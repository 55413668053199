import {
  AgencySalesperson,
  FormCode,
  FormInstance,
  MaterialisedPropertyData,
  SigningAuthorityType,
  SigningPartySourceType,
  SigningPartySourceTypeToStringPrint,
  SigningSessionFieldType,
  sourceTypeGroup
} from '@property-folders/contract';
import { Content } from 'pdfmake/interfaces';
import { FileStorage } from '../../../offline/fileStorage';
import { v4 } from 'uuid';
import {
  Agent,
  FormCodeUnion,
  InstanceHistory,
  PartyType,
  SignerProxyType,
  SigningParty,
  SigningSessionField,
  SourceRepresentationLevel
} from '@property-folders/contract/property';
import { buildSignatureSection } from '../../pdf-worker/buildSignatureSection';
import { Predicate } from '../../../predicate';
import { FormUtil } from '../../form';
import { isSubscriptionForm } from '../../../subscription-forms/isSubscriptionForm';
import { BelongingEntityMeta } from '../../../redux-reducers/entityMeta';
import { FormTypes, mapSigningPartySourceTypeToCategory } from '../../../yjs-schema/property/form';
import { uniq, difference } from 'lodash';
import { isAuthorityPartyFilled } from './vendorSection';

// needs SigningProxySnapshotAddon
export interface ISignatureSectionSigner {
  /**
   * If signing has not occurred,
   * then this id should be placed somewhere so the signing system knows where to inject a signature
   */
  placeholderId?: string;
  /**
   * source party ID, used to identify signers as part of the same party. Note that IDs may not match
   * between preview and final document. Further checks and likely adjustments will need to be made
   * to use it for this purpose
   */
  originalPartySourceId?: string
  /**
   * Source hierarchy, to decode the nested list of vendors
   */
  sourceHierarchy?: SourceRepresentationLevel[]
  /**
   * ID of the signing.party signer
   */
  partySignerId?: string
  /**
   * Phrase varies based on the name and capacity of the signer
   */
  signingPhrase?: string;
  /**
   * Name of signatory
   */
  signingPartyIdentifier: string;
  /**
   * Name of represented party, or self
   */
  partyIdentifier?: string
  /**
   * If signing has occurred, this is the image
   */
  image?: Blob | string;
  /**
   * If signing has occurred, this is when it occurred.
   * Should be ms since epoch, e.g. from Date.now()
   */
  timestamp?: number;
  /**
   * Identifies what sort of party they are, eg Agent, Vendor, Purchaser. Not symbolic, printed
   * directly
   */
  partyType?: string
  partyTypeEnum?: SigningPartySourceType
  partyTypeDataModel?: PartyType
  /**
   * Flag the party as being removed
   */
  removing?: boolean
  /**
   * Flag the party as existing in the most recent snapshot. A non nullish value indicates that a
   * snapshot has been used for calculation
   */
  added?: boolean
  authority?: SigningAuthorityType
  /**
   * If not self identified, explains signatory type. eg Director, Secretary, Attorney
   */
  signerPartyAuthorityDisplay?: string,
  /**
   * If we have a proxy type, this was the original signer name.
   * copied from signingPartyIdentifier
   */
  proxyForOriginalIdentifier: string,
  /**
   * The authority by which the proxy signed
   */
  proxyAuthority: SignerProxyType
  /**
   * If true, hide the witness field
   */
  noWitness?: boolean
}
type PartySigningExtension = {partyTypeLabel: string, partyTypeEnum: SigningPartySourceType};
export type AgentWithAgency = AgencySalesperson & {agency: Agent};

type SignersFormInstanceSubset = {
  signing?: {
    parties?:SigningParty[],
    session?: {
      fields?: SigningSessionField[]
    }
  },
  formCode: FormCodeUnion
};

function generateVendor(noWitness?: boolean): ISignatureSectionSigner {
  return {
    partyType: 'Vendor',
    partyTypeEnum: SigningPartySourceType.Vendor,
    placeholderId: v4(),
    partySignerId: v4(),
    noWitness
  };
}
export class SignatureSection {
  public static build(signers: ISignatureSectionSigner[], previousInstance?: FormInstance): Content[] {
    return buildSignatureSection(signers, previousInstance);
  }

  public static async buildSignersFromSigningSession(instance: SignersFormInstanceSubset, property: MaterialisedPropertyData, opts?: { previousInstance?: FormInstance, noWitness?: boolean
}) {
    if (!instance.signing) return [];
    const { noWitness: noWitnessParam } = opts ?? {};
    const noWitness = (instance?.formCode && FormTypes[instance.formCode].formFamily === FormCode.RSAA_SalesAgencyAgreement) || noWitnessParam;
    const parties = instance.signing?.parties || [];
    const partySnapshots = new Map(parties.map(party => [party.id, {
      snapshot: party.snapshot,
      sourceHierarchy: party.source.representationHierarchy,
      type: party.source.type,
      removing: party.source.isRemoving,
      added: party.source.added,
      proxy: {
        name: party.proxyName,
        authority: party.proxyAuthority
      }
    }]));

    const fields = instance.signing?.session?.fields || [];
    const signatures = fields.filter(field => field.type === SigningSessionFieldType.Signature);

    const signers: ISignatureSectionSigner[] = [];
    for (const signature of signatures) {
      const { snapshot, type, removing, added, proxy, sourceHierarchy } = partySnapshots.get(signature.partyId) ?? {};

      if (!snapshot) {
        return [];
      }

      const proxyMode = !!(Predicate.proxyNotSelf(proxy?.authority) && proxy.name);
      const proxyAdd = Predicate.proxyOnBehalfOf(proxy?.authority) ? {
        proxyForOriginalIdentifier: snapshot.signingPartyIdentifier,
        proxyAuthority: proxy.authority
      } : {};

      const possiblySourceData = sourceTypeGroup.get(type??SigningPartySourceType.Error) === SigningPartySourceType.Vendor
        ? property?.vendors?.find(v => v.id === snapshot.originalPartySourceId)
        : sourceTypeGroup.get(type??SigningPartySourceType.Error) === SigningPartySourceType.Purchaser
          ? property?.purchasers?.find(v => v.id === snapshot.originalPartySourceId)
          : undefined;
      signers.push({
        ...proxyAdd,
        placeholderId: signature.id,
        originalPartySourceId: snapshot.originalPartySourceId,
        partySignerId: signature.partyId,
        sourceHierarchy,
        signingPartyIdentifier: proxyMode ? proxy.name : snapshot.signingPartyIdentifier,
        partyIdentifier: snapshot.partyIdentifier,
        signingPhrase: snapshot.filledSigningPhrase,
        image: signature.file?.id
          ? (await FileStorage.read(signature.file.id))?.data
          : undefined,
        timestamp: signature.timestamp,
        partyType: SigningPartySourceTypeToStringPrint(type??SigningPartySourceType.Error),
        partyTypeEnum: type,
        partyTypeDataModel: possiblySourceData?.partyType,
        removing,
        added,
        authority: possiblySourceData?.authority,
        noWitness
      });
    }
    const formType = instance?.formCode && FormTypes[instance.formCode];

    if (formType && formType.parties?.length) {
      // Should this move to buildSignersFromSigningSession in signatureSection.ts? That has noWitness being calculated
      // Yes we are using the deprecated method. This is what is used internally by mapSigningPartySourceTypeToCategory, and we're beyond a party and into a ISigner whatever, so the transformation should have happened by now
      const sourceCategories = signers.map(is => mapSigningPartySourceTypeToCategory(is.partyTypeEnum));
      const presentCategories = uniq(sourceCategories);
      const expectedTypeCategories = formType.parties.map(p=>p.type);
      const missing = difference(expectedTypeCategories, presentCategories);
      for (const category of missing) {
        switch (category) {
          case 'vendor': {
            // Default is 2 blank vendors
            signers.push(generateVendor(noWitness), generateVendor(noWitness));
            break;
          }
          default:
            console.warn('Not currently adding in blanks for', category);
        }
      }
    }
    return signers;
  }

  public static async buildSignersForPreview({
    propertyData, previousInstance,formCode,instHistory, previousData, memberEntities
  }:{
    propertyData: MaterialisedPropertyData,
    previousData?: MaterialisedPropertyData | undefined,
    previousInstance?: FormInstance,
    formCode: FormCodeUnion,
    instHistory?: InstanceHistory | undefined,
    memberEntities: BelongingEntityMeta
  }) {
    const fakeInstance: SignersFormInstanceSubset = { signing: {}, formCode };
    propertyData = structuredClone(propertyData);
    maskOutSignersIfEmpty(propertyData);
    FormUtil.ensureSigningParties(fakeInstance.signing, formCode, { propertyData: propertyData, history: instHistory });
    const buildSigningField = FormUtil.buildSigningFieldForPartyNonCustomFactory(false);
    fakeInstance.signing.session = { fields: fakeInstance.signing.parties?.map(buildSigningField) };

    FormUtil.ensureSigningSession({
      signing: fakeInstance.signing!,
      formCode,
      outForSigningData: {
        sessionId: v4(),
        baseFile: { contentType: 'application/octet-stream', id: v4() }, accompanyingFileIds: {}, initiator: { email: '', entity: { id: '', name: '' }, id: '', name: '' },
        memberEntities
      },
      dataBinder: { get: () => propertyData },
      isSubscriptionForm: isSubscriptionForm(formCode),
      previousData
    });

    const rVal = await this.buildSignersFromSigningSession(fakeInstance, propertyData, previousInstance);

    return await rVal;
  }
}

const maskOutSignersIfEmpty = (propertyData: MaterialisedPropertyData) => {
  for (const topLevelKey of ['vendors', 'purchasers']) {
    const parties = propertyData?.[topLevelKey];
    if (!Array.isArray(parties)) continue;
    if (isAuthorityPartyFilled(parties)) continue;
    parties.splice(0,parties.length, { id: v4() }, { id: v4() });
  }
};

import {
  CustomFieldConfiguration,
  CustomFieldType,
  MaterialisedPropertyData,
  SigningParty,
  SigningPartySnapshot,
  SigningSessionField
} from '@property-folders/contract';
import { ExtractedField, ImagePagePlacement, PageDimension, StaticTextPlacement } from './types';
import { mapSigningPartySourceTypeToCategory, PartyCategory } from '../../yjs-schema/property/form';
import { buildFieldName, FieldType } from '../../signing/pdf-form-field';
import { ArrayUtil } from '../array';
import {
  Color,
  degrees,
  PDFDocument,
  PDFFont,
  PDFForm,
  PDFPage,
  RGB,
  rgb,
  Rotation,
  RotationTypes,
  StandardFonts
} from '@cantoo/pdf-lib';
import {
  proposedPrefixTextGen,
  SigningPlacementStrategy,
  summariseAddressesOrTitles,
  summariseContractSettlement
} from '../../yjs-schema/property';
import { CoordinateMath, HtmlPdfReflection, PageDims, Point } from '../coords';
import { v4 } from 'uuid';
import { customFieldMetas, defaultFontSize, defaultLineHeight } from '@property-folders/contract/property/meta';
import { getPartyIdsActuallySigningFromCustomFields } from '../form';
import { ColourUtil } from '../colour';
import fontkit from '@pdf-lib/fontkit';
import { Predicate } from '../../predicate';
import { FontEmbedder, getFontData, mapFontName, SupportedFontFamily } from './font-embedder';
import { calculateFinalPrice2 } from '../pdfgen/sections/contract-price';
import { getPrimaryContact } from '../dataExtract';

export function getPlacementPosition({
  pagePlacements,
  strategy,
  page
}: {
  pagePlacements: ImagePagePlacement[];
  strategy: SigningPlacementStrategy;
  page: PageDimension
}) {
  switch (strategy.type) {
    case 'expand': {
      const xs = pagePlacements.flatMap(p => [p.x, p.x + p.width]);
      const ys = pagePlacements.flatMap(p => [p.y, p.y + p.height]);
      const minY = Math.min(...ys) - (strategy.expandDown || 0);
      const maxY = Math.max(...ys) + (strategy.expandUp || 0);
      const minX = Math.min(...xs) - (strategy.expandLeft || 0);
      const maxX = Math.max(...xs) + (strategy.expandRight || 0);
      const width = Math.max(...pagePlacements.flatMap(p => p.width)) + (strategy.expandRight || 0);

      const placementX = minX + (strategy.signedText?.width || 0);

      return {
        placement: {
          name: pagePlacements[0].name,
          x: placementX,
          y: minY,
          width,
          height: maxY - minY
        },
        minX,
        minY,
        maxX,
        maxY
      };
    }

    case 'fill-contiguous-space': {
      const pageWidth = page.width;
      const minX = strategy.marginLeft ?? 0;
      const placementX = minX + (strategy.signedText?.width || 0);
      const maxX = pageWidth - (strategy.marginRight || 0);
      const ys = pagePlacements.flatMap(p => [p.y, p.y + p.height]);
      // coordinate system increases from 0,0 in the bottom left to X,Y in the top right.
      // therefore, to expand downwards you subtract something from the minimum y value
      const minY = Math.min(...ys) - (strategy.expandDown || 0);
      const maxY = Math.max(...ys) + (strategy.expandUp || 0);

      return {
        placement: {
          name: pagePlacements[0].name,
          x: placementX,
          y: minY,
          width: maxX - placementX,
          height: maxY - minY
        },
        minX,
        minY,
        maxX,
        maxY
      };
    }
  }
}

function applyPlacementStrategy(
  strategy: SigningPlacementStrategy,
  partyType: PartyCategory,
  allPlacements: ImagePagePlacement[],
  pages: PageDimension[],
  useGroups: boolean = true,
  applyInitials: boolean = false // by default, we'll skip over initials
): {
  newPlacements: ImagePagePlacement[],
  replacedPlacements: ImagePagePlacement[],
  textInjections: StaticTextPlacement[],
} {
  switch (strategy.type) {
    case 'expand': {
      const matchingPlacements = allPlacements.filter(p => p.party?.partyType === partyType);
      const pageGroups = useGroups
        ? ArrayUtil.groupBy(matchingPlacements, x => x.pageIndex)
        : ArrayUtil.groupBy(matchingPlacements, x => x.name);

      const newPlacements: ImagePagePlacement[] = [];
      const textInjections: StaticTextPlacement[] = [];

      for (const [_, pagePlacements] of pageGroups.entries()) {
        const pageIndex = pagePlacements[0].pageIndex;

        if (!useGroups && pagePlacements[0].party?.fieldType === 'initial' && !applyInitials) {
          newPlacements.push(...pagePlacements);
          continue;
        }

        const { minX, minY, maxY, placement: placementPosition } = getPlacementPosition({
          pagePlacements,
          strategy,
          page: pages[pageIndex]
        });

        newPlacements.push({
          pageIndex,
          party: {
            partyType,
            mergeCount: pagePlacements.length,
            number: Math.min(...pagePlacements.map(p => p.party?.number || Number.MAX_SAFE_INTEGER)),
            fieldType: pagePlacements[0].party?.fieldType ?? 'signature' // default, will probably be overwritten
          },
          ...placementPosition,
          strategy
        });

        if (strategy.signedText) {
          const offsetX = (strategy.signedText.offsetX ?? 0);
          const offsetY = + (strategy.signedText.offsetY ?? 0);
          const left = minX + offsetX;

          textInjections.push({
            pageIndex,
            text: 'Signed:',
            left,
            right: left + strategy.signedText.width,
            top: maxY + offsetY,
            bottom: minY + offsetY,
            size: strategy.signedText.size
          });
        }
      }

      return {
        newPlacements,
        textInjections,
        replacedPlacements: matchingPlacements
      };
    }

    case 'fill-contiguous-space': {
      const matchingPlacements = allPlacements.filter(p => p.party?.partyType === partyType);
      const pageGroups = useGroups
        ? ArrayUtil.groupBy(matchingPlacements, x => x.pageIndex)
        : ArrayUtil.groupBy(matchingPlacements, x => x.name);

      const newPlacements: ImagePagePlacement[] = [];
      const textInjections: StaticTextPlacement[] = [];

      for (const [_, pagePlacements] of pageGroups.entries()) {
        const pageIndex = pagePlacements[0].pageIndex;

        if (!useGroups && pagePlacements[0].party?.fieldType === 'initial' && !applyInitials) {
          newPlacements.push(...pagePlacements);

          continue;
        }

        const { placement: placementPosition, minX, maxY, minY } = getPlacementPosition({
          pagePlacements,
          strategy,
          page: pages[pageIndex]
        });

        newPlacements.push({
          pageIndex,
          party: {
            partyType: partyType,
            mergeCount: pagePlacements.length,
            number: Math.min(...pagePlacements.map(p => p.party?.number || Number.MAX_SAFE_INTEGER)),
            fieldType: pagePlacements[0].party?.fieldType ?? 'signature' // default, will probably be overwritten
          },
          ...placementPosition,
          strategy
        });

        if (strategy.signedText) {
          const offsetX = (strategy.signedText.offsetX ?? 0);
          const offsetY = + (strategy.signedText.offsetY ?? 0);
          const left = minX + offsetX;

          textInjections.push({
            pageIndex,
            text: 'Signed:',
            left,
            right: left + strategy.signedText.width,
            top: maxY + offsetY,
            bottom: minY + offsetY,
            size: strategy.signedText.size
          });
        }
      }

      return {
        newPlacements,
        textInjections,
        replacedPlacements: matchingPlacements
      };
    }

    default: {
      return { newPlacements: [], replacedPlacements: [], textInjections: [] };
    }
  }
}

export function applyPlacementStrategies({
  placements,
  placementStrategies,
  pages,
  useGroups = true
}: {
  placements: ImagePagePlacement[],
  placementStrategies?: { [key in PartyCategory]?: SigningPlacementStrategy | undefined },
  pages: PageDimension[],
  useGroups?: boolean
}): { placements: ImagePagePlacement[], texts: StaticTextPlacement[] } {
  if (!placementStrategies) return { placements, texts: [] };

  const placementsWhereStrategyWasApplied = new Set<ImagePagePlacement>();
  const resultPlacements: ImagePagePlacement[] = [];
  const resultTexts: StaticTextPlacement[] = [];

  for (const placement of placements) {
    if (placementsWhereStrategyWasApplied.has(placement)) {
      continue;
    }

    const partyType = placement.party?.partyType;
    if (!partyType) {
      continue;
    }

    const strategy: SigningPlacementStrategy = placementStrategies[partyType] || (placementStrategies as any)['default'];
    if (!strategy) {
      continue;
    }

    const { newPlacements, replacedPlacements, textInjections } = applyPlacementStrategy(
      strategy,
      partyType,
      placements,
      pages,
      useGroups
    );

    resultPlacements.push(...newPlacements);
    resultTexts.push(...textInjections);
    replacedPlacements.forEach(p => placementsWhereStrategyWasApplied.add(p));
  }

  // include any unreplaced placements
  for (const placement of placements) {
    if (placementsWhereStrategyWasApplied.has(placement)) continue;
    resultPlacements.push(placement);
  }

  return { placements: resultPlacements, texts: resultTexts };
}

export async function injectSigningLocations({
  placements: originalPlacements,
  parties,
  fields,
  pdf: pdfDoc,
  pages,
  placementStrategies,
  debug,
  useGroups = true
}: {
  placements: ImagePagePlacement[],
  parties: SigningParty[],
  fields: (SigningSessionField &  { placement?: ImagePagePlacement })[],
  pdf: PDFDocument,
  pages: PageDimension[],
  placementStrategies?: { [key in PartyCategory | string]?: SigningPlacementStrategy }
  debug?: boolean
  useGroups?: boolean;
}) {
  const { placements, texts } = applyPlacementStrategies({
    placements: originalPlacements,
    placementStrategies,
    pages,
    useGroups
  });

  const placementGroups = ArrayUtil.groupBy<ImagePagePlacement, PartyCategory>(
    placements,
    placement => useGroups ? placement.party?.partyType : `${placement.party?.partyType}${placement.party?.number}` as any);

  const partyGroups: Map<PartyCategory, SigningParty[]> = ArrayUtil.groupBy<SigningParty, PartyCategory>(
    parties,
    party => useGroups ? mapSigningPartySourceTypeToCategory(party.source.type) : party.source.originalType as PartyCategory); // can use this function here

  const formFieldManifest = [...partyGroups.entries()]
    .flatMap(([category, groupedParties]) => {
      if (!useGroups) {
        const originalType = groupedParties[0].source.originalType ?? '';
        category = originalType.replace(/\d+/, '') == originalType
          ? originalType + '1'
          : originalType as any;
      }

      const groupedPlacements = placementGroups.get(category);
      if (!groupedPlacements?.length) {
        throw new Error(`Expected placement category ${category} to exist, but found none`);
      }

      return generateFormFieldManifest({
        locations: groupedPlacements,
        parties: groupedParties,
        fields,
        useGroups
      });
    });

  const firstSize = {
    width: formFieldManifest.at(0)?.positions.at(0)?.width || 1000,
    height: formFieldManifest.at(0)?.positions.at(0)?.height || 1000
  };
  const minSizes = formFieldManifest.flatMap(ff => ff.positions.map(pos => ({
    width: pos.width,
    height: pos.height
  }))).reduce((a, b) => ({
    width: Math.min(a.width, b.width),
    height: Math.min(a.height, b.height)
  }), firstSize);

  const pdfForm = pdfDoc.getForm();
  const white = rgb(1, 1, 1);
  for (const field of formFieldManifest) {
    const pdfField = pdfForm.createTextField(field.id);
    for (const pos of field.positions) {
      const page = pdfDoc.getPage(pos.page);
      page.drawRectangle({
        width: pos.width,
        height: pos.height,
        x: pos.x,
        y: pos.y,
        color: debug ? rgb(0, 1, 0) : white
      });

      const width = Math.max(minSizes.width, pos.width);
      const height = Math.max(minSizes.height, pos.height);

      pdfField.addToPage(page, {
        width,
        height,
        x: pos.x,
        y: pos.y + pos.height - height,
        backgroundColor: white,
        borderColor: debug ? rgb(0, 0, 1) : undefined,
        borderWidth: debug ? 2 : undefined
      });
    }
    for (const text of texts) {
      const page = pdfDoc.getPage(text.pageIndex);
      page.drawRectangle({
        width: text.right - text.left,
        height: text.top - text.bottom,
        x: text.left,
        y: text.bottom,
        color: debug ? rgb(1, 0, 0) : white
      });
      page.drawText(text.text, {
        size: text.size,
        x: text.left,
        y: text.top - text.size
      });
    }
  }
  pdfDoc.setAuthor('reaforms');

  return {
    fields
  };
}

export function generateFormFieldManifest({
  locations,
  parties,
  fields,
  useGroups = true
}: {
  locations: ImagePagePlacement[],
  parties: SigningParty[],
  fields: (SigningSessionField & { placement?: ImagePagePlacement })[],
  useGroups?: boolean;
}): ExtractedField[] {
  if (!parties.length) return [];
  if (!locations.length) return [];

  // we can only have multiple signing locations if we are signing individual fields
  const multipleLocations = !useGroups;

  if (multipleLocations) {
    const extractedFields = fields.map(field => {
      const party = parties.find(p => p.id === field.partyId);

      if (!party) {
        return;
      }

      const fl = field.placement
        ? [ field.placement ]
        : locations.filter(l => {
          if (!l.party || !party) {
            return false;
          }

          return l.name === field.originalFieldName;
        });

      return {
        id: field
          ? buildFieldName({ type: FieldType.FieldValue, fieldId: field.id })
          : buildFieldName({ type: FieldType.System, fieldId: 'NO_OP' }),
        positions: fl.map(p => ({
          width: p.width,
          height: p.height,
          y: p.y,
          page: p.pageIndex,
          x: p.x
        }))
      };
    });

    return extractedFields
      .filter(Predicate.isNotNull)
      .filter(f => f.positions.length > 0);
  }

  if (parties.length <= locations.length) {
    return locations.flatMap((location, index) =>
      generateFieldManifestForSingleLocation({
        location,
        numSpaces: 1,
        parties: parties.slice(index, index + 1),
        fields
      }));
  }

  // split up parties amongst the locations
  const numSpacesPerLocation = Math.max(parties.length / locations.length);
  const partyChunks = ArrayUtil.chunk(parties, numSpacesPerLocation);
  return partyChunks.flatMap((partyChunk, index) => {
    const location = locations[index];
    return generateFieldManifestForSingleLocation({
      location,
      parties: partyChunk,
      fields,
      numSpaces: numSpacesPerLocation
    });
  });
}

function generateFieldManifestForSingleLocation({
  location,
  parties,
  fields,
  numSpaces
}: {
  location: ImagePagePlacement,
  parties: SigningParty[],
  fields: SigningSessionField[],
  numSpaces: number
}): ExtractedField[] {
  if (parties.length > numSpaces) throw new Error(`Cannot allocate ${parties.length} parties to ${numSpaces} spaces`);

  const numDivisions = location.party?.mergeCount
    ? Math.max(location.party.mergeCount, parties.length)
    : parties.length;
  const ratio = location.width / location.height;
  const colsFirst = location.strategy?.initialDivisionDirection === 'cols-first'
    ? true
    : location.strategy?.initialDivisionDirection === 'rows-first'
      ? false
      : Boolean(ratio / 2.5 >= 2);
  const { numRows, numCols, signatureHeight, signatureWidth } = colsFirst
    ? getDivisionColsFirst({ location, numParties: numDivisions, numSpaces })
    : getDivisionRowsFirst({ location, numParties: numDivisions, numSpaces });

  const result: ExtractedField[] = [];
  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      const index = (row * numCols) + col;
      const party = parties.at(index);
      const field = party
        ? fields.find(f => f.partyId === party.id)
        : undefined;
      const x = col * signatureWidth + location.x;
      // y coordinate origin is the bottom, but we want things to appear in order from top to bottom.
      // so grow downwards from the 'top' of the signature location:
      const y = (location.y + location.height) - (row + 1) * signatureHeight;//row * signatureHeight + location.y;
      result.push({
        id: field
          ? buildFieldName({ type: FieldType.FieldValue, fieldId: field.id })
          : buildFieldName({ type: FieldType.System, fieldId: 'NO_OP' }),
        positions: [{
          page: location.pageIndex,
          width: signatureWidth,
          height: signatureHeight,
          x,
          y
        }]
      });
    }
  }

  return result;
}

function getDivisionRowsFirst({
  location,
  numParties,
  numSpaces
}: {
  location: ImagePagePlacement,
  numParties: number,
  numSpaces: number
}) {
  // this signature height is just used for calculating row/col counts.
  // actual signature height will expand to fill the available space.
  const minimalSignatureHeight = Math.min(location.height, 28);
  // e.g. if the space can comfortably fit a party per row, then just go with that!
  // commonly this will be on the variable-signature-section forms where the number of parties is the number of
  // signatures.
  const maximalSignatureHeight = Math.max(minimalSignatureHeight, location.height / numParties);
  const numRows = Math.floor(location.height / maximalSignatureHeight);
  const numCols = Math.ceil(numSpaces / numRows);
  const signatureWidth = location.width / numCols;
  const signatureHeight = location.height / numRows;

  return {
    numRows,
    numCols,
    signatureWidth,
    signatureHeight
  };
}

function getDivisionColsFirst({
  location,
  numParties,
  numSpaces
}: {
  location: ImagePagePlacement,
  numParties: number,
  numSpaces: number
}) {
  const minimalSignatureWidth = Math.min(location.width, 120);
  const maximalSignatureWidth = Math.max(minimalSignatureWidth, location.width / numParties);
  const numCols = Math.floor(location.width / maximalSignatureWidth);
  const numRows = Math.ceil(numSpaces / numCols);
  const signatureWidth = location.width / numCols;
  const signatureHeight = location.height / numRows;

  return {
    numRows,
    numCols,
    signatureWidth,
    signatureHeight
  };
}

export async function injectCustomFields({
  customFields,
  pdf,
  fieldIdMap,
  parties,
  property,
  additionalPartyIds,
  debug
}: {
  customFields: CustomFieldConfiguration[],
  pdf: PDFDocument,
  fieldIdMap: Map<string, string>,
  parties: SigningParty[],
  property: MaterialisedPropertyData,
  additionalPartyIds?: string[],
  debug?: boolean
}) {
  const pdfForm = pdf.getForm();
  const partiesActuallySigning = getPartyIdsActuallySigningFromCustomFields(customFields, undefined, additionalPartyIds);
  const embedder = new FontEmbedder(pdf);
  const getFormFieldId = (customFieldId: string) => {
    const fieldId = fieldIdMap.get(customFieldId);
    if (fieldId) return fieldId;

    const newId = v4();
    fieldIdMap.set(customFieldId, newId);
    return newId;
  };

  // preload fonts. do this step first so we can figure out if it will be full/subset embedding
  const preload: { name?: string, subset: boolean }[] = [];
  for (const customField of customFields) {
    switch (customField.type) {
      case CustomFieldType.remoteText:
        preload.push({ name: customField.fontFamily, subset: false });
        break;
      default:
        if ('fontFamily' in customField) {
          preload.push({ name: customField.fontFamily, subset: true });
        }
        break;
    }
  }
  await embedder.embedBatch(preload);

  for (const customField of customFields) {
    const { page, dims } = getPageInfo(pdf, customField.position.page);
    const rotate = degrees(dims.degrees);
    const point = CoordinateMath.orientToPage({
      x: customField.position.x,
      y: customField.position.y - 1
    }, dims, customField.reflection ?? HtmlPdfReflection);
    const rect = CoordinateMath.absRect({
      x: point.x,
      y: point.y,
      width: customField.position.width,
      height: -customField.position.height
    }, dims.degrees);
    if (debug) {
      drawDebugRect(page, { ...rect, rotate, color: rgb(1, 0, 0) });
    }

    switch (customField.type) {
      case CustomFieldType.initials:
      case CustomFieldType.signature: {
        const fieldId = getFormFieldId(customField.id);
        addTextField(pdfForm, page, {
          name: buildFieldName({
            type: FieldType.FieldValue,
            fieldId
          }),
          ...rect,
          rotate,
          fontSize: defaultFontSize,
          backgroundColor: customField.bg ? ColourUtil.toPdfLibColor(customField.bgColour) : undefined
        });
        break;
      }
      case CustomFieldType.timestamp: {
        if (!partiesActuallySigning.has(customField.partyId)) break;
        addTextField(pdfForm, page, {
          name: buildFieldName({
            type: FieldType.PartyTimestamp,
            partyId: customField.partyId
          }),
          ...rect,
          rotate,
          fontSize: customField.fontSize || defaultFontSize,
          textColor: ColourUtil.toPdfLibColor(customField.fontColour),
          backgroundColor: customField.bg ? ColourUtil.toPdfLibColor(customField.bgColour) : undefined
        });
        break;
      }
      case CustomFieldType.remoteText: {
        const fieldId = getFormFieldId(customField.id);
        addTextField(pdfForm, page, {
          name: buildFieldName({
            type: FieldType.FieldValue,
            fieldId
          }),
          ...rect,
          rotate,
          fontSize: customField.fontSize || defaultFontSize,
          textColor: ColourUtil.toPdfLibColor(customField.fontColour),
          backgroundColor: customField.bg ? ColourUtil.toPdfLibColor(customField.bgColour) : undefined,
          font: customField.fontFamily ? await embedder.embed(customField.fontFamily, false) : undefined,
          multiline: customField.multiline
        });
        break;
      }
      case CustomFieldType.remoteRadio:
      case CustomFieldType.remoteCheck:
      {
        const fieldId = getFormFieldId(customField.id);
        // radio takes up a square based on its height
        // then draw text in the remaining space
        // noinspection JSSuspiciousNameCombination
        const fieldRect = CoordinateMath.absRect({
          x: point.x,
          y: point.y,
          width: customField.position.height,
          height: -customField.position.height
        }, dims.degrees);
        if (customField.type === CustomFieldType.remoteRadio) {
          addRadioField(pdfForm, page, {
            name: buildFieldName({
              type: FieldType.FieldValue,
              fieldId
            }),
            ...fieldRect,
            rotate,
            group: customField.group
          });
        } else {
          addCheckboxField(pdfForm, page, {
            name: buildFieldName({
              type: FieldType.FieldValue,
              fieldId
            }),
            ...fieldRect,
            rotate
          });
        }

        if (customField.label) {
          const labelRect = CoordinateMath.absRect({
            x: point.x + customField.position.height,
            y: point.y,
            width: customField.position.width - customField.position.height,
            height: -customField.position.height
          }, dims.degrees);
          drawText(page, {
            text: ' ' + customField.label.trim(),
            ...labelRect,
            rotate,
            fontSize: customField.fontSize || defaultFontSize,
            font: await embedder.embed(customField.fontFamily, true),
            lineHeight: customField.lineHeight || defaultLineHeight,
            color: ColourUtil.toPdfLibColor(customField.fontColour)
          });
        }
        break;
      }
      case CustomFieldType.purchaserName: {
        addTextField(pdfForm, page, {
          name: buildFieldName({
            type: FieldType.Serve,
            fieldId: 'PURCHASER_NAME'
          }),
          ...rect,
          rotate,
          fontSize: defaultFontSize,
          textColor: ColourUtil.toPdfLibColor(customField.fontColour),
          backgroundColor: customField.bg ? ColourUtil.toPdfLibColor(customField.bgColour) : undefined
        });
        break;
      }
      case CustomFieldType.purchaserAddress: {
        addTextField(pdfForm, page, {
          name: buildFieldName({
            type: FieldType.Serve,
            fieldId: 'PURCHASER_ADDRESS'
          }),
          ...rect,
          rotate,
          fontSize: defaultFontSize,
          textColor: ColourUtil.toPdfLibColor(customField.fontColour),
          backgroundColor: customField.bg ? ColourUtil.toPdfLibColor(customField.bgColour) : undefined
        });
        break;
      }
      case CustomFieldType.contractDate: {
        addTextField(pdfForm, page, {
          name: buildFieldName({
            type: FieldType.Serve,
            fieldId: 'CONTRACT_DATE'
          }),
          ...rect,
          rotate,
          fontSize: defaultFontSize,
          textColor: ColourUtil.toPdfLibColor(customField.fontColour),
          backgroundColor: customField.bg ? ColourUtil.toPdfLibColor(customField.bgColour) : undefined
        });
        break;
      }
      case CustomFieldType.text:
        drawText(page, {
          text: customField.text,
          ...rect,
          rotate,
          fontSize: customField.fontSize || defaultFontSize,
          font: await embedder.embed(customField.fontFamily, true),
          lineHeight: customField.lineHeight || defaultLineHeight,
          color: ColourUtil.toPdfLibColor(customField.fontColour),
          backgroundColor: customField.bg ? ColourUtil.toPdfLibColor(customField.bgColour) : undefined
        });
        break;
      case CustomFieldType.checkmark:
        drawText(page, {
          text: customField.checkmark,
          ...rect,
          rotate,
          fontSize: customField.fontSize || defaultFontSize,
          font: await embedder.embed('dejavusans-checkmarks'),
          lineHeight: customField.fontSize || defaultFontSize,
          color: ColourUtil.toPdfLibColor(customField.fontColour),
          backgroundColor: customField.bg ? ColourUtil.toPdfLibColor(customField.bgColour) : undefined
        });
        break;
      default:
        if (customFieldMetas[customField.type]?.predefinedText) {
          const party = 'partyId' in customField
            ? parties.find(p => p.id === customField.partyId)
            : undefined;
          drawText(page, {
            text: getTextFieldText(customField, party, property),
            ...rect,
            rotate,
            fontSize: customField.fontSize || defaultFontSize,
            font: await embedder.embed(customField.fontFamily, true),
            lineHeight: customField.lineHeight || defaultLineHeight,
            color: ColourUtil.toPdfLibColor(customField.fontColour),
            backgroundColor: customField.bg ? ColourUtil.toPdfLibColor(customField.bgColour) : undefined
          });
        }
        break;
    }
  }
}

export function getTextFieldText(
  typeRaw: CustomFieldType | CustomFieldConfiguration,
  party: SigningParty | SigningPartySnapshot | undefined,
  property: MaterialisedPropertyData | undefined
): string {
  const type = typeof typeRaw === 'string'
    ? typeRaw
    : typeRaw.type;
  const snapshot = party == null
    ? undefined
    : 'snapshot' in party
      ? party.snapshot
      : party as SigningPartySnapshot;
  switch (type) {
    case CustomFieldType.name:
      return snapshot?.name || '';
    case CustomFieldType.email:
      return snapshot?.email || '';
    case CustomFieldType.phone:
      return snapshot?.phone || '';
    case CustomFieldType.company:
      return snapshot?.company || '';
    case CustomFieldType.abn:
      return snapshot?.abn || '';
    case CustomFieldType.address:
      return snapshot?.addressSingleLine || '';
    case CustomFieldType.authority:
      return snapshot?.filledSigningPhrase || '';
    case CustomFieldType.saleAddress:
      return summariseAddressesOrTitles({ saleAddrs: property?.saleAddrs });
    case CustomFieldType.saleTitle:
      return summariseAddressesOrTitles({ saleTitles: property?.saleTitles });
    case CustomFieldType.proposedAllotments:
      return proposedPrefixTextGen(
        property?.titleDivision?.depositChoice,
        property?.titleDivision?.proposedLots,
        property?.saleTitles
      );
    case CustomFieldType.propertySummary:
      return summariseAddressesOrTitles(property);
    case CustomFieldType.primaryVendor:
      return getPrimaryContact(property?.vendors, property?.primaryVendor)?.fullLegalName || '';
    case CustomFieldType.primaryPurchaser:
      return getPrimaryContact(property?.purchasers, property?.primaryPurchaser)?.fullLegalName || '';
    case CustomFieldType.purchasePrice: {
      return calculateFinalPrice2(property?.vendorGst, property?.contractPrice)?.purchasePriceDisplay ?? '';
    }
    case CustomFieldType.settlement:
      return summariseContractSettlement(property) || '';
    case CustomFieldType.salesperson: {
      const names = property?.agent?.flatMap?.(agent => agent?.salesp?.map(sp => sp.name) ?? []) ?? [];
      return names.length ? names.join(', ') : '';
    }
    case CustomFieldType.text:
      return typeof typeRaw === 'object' && typeRaw.type === CustomFieldType.text
        ? typeRaw.text
        : '';
    case CustomFieldType.checkmark:
      return typeof typeRaw === 'object' && typeRaw.type === CustomFieldType.checkmark
        ? typeRaw.checkmark
        : '';
    case CustomFieldType.remoteText:
      return typeof typeRaw === 'object' && typeRaw.type === CustomFieldType.remoteText
        ? typeRaw.text || ''
        : '';
    default:
      return '';
  }
}

function addTextField(form: PDFForm, page: PDFPage, opts: {
  name: string,
  x: number,
  y: number,
  width: number,
  height: number,
  rotate: Rotation,
  fontSize?: number,
  backgroundColor?: Color,
  textColor?: Color,
  font?: PDFFont,
  multiline?: boolean,
}) {
  const field = form.createTextField(opts.name);

  if (opts.backgroundColor) {
    page.drawRectangle({
      x: opts.x,
      y: opts.y,
      height: opts.height,
      width: opts.width,
      rotate: opts.rotate,
      color: opts.backgroundColor
    });
  }
  field.addToPage(page, {
    ...opts,
    borderColor: undefined,
    borderWidth: undefined
  });
  if (opts.multiline) {
    field.enableMultiline();
  }
  if (opts.fontSize !== undefined) field.acroField.setFontSize(opts.fontSize);
}

function addCheckboxField(form: PDFForm,  page: PDFPage, opts: {
  name: string,
  x: number,
  y: number,
  width: number,
  height: number,
  rotate: Rotation,
}) {
  const field = form.createCheckBox(opts.name);
  field.addToPage(page, {
    ...opts
  });
}

function ensureRadioGroup(form: PDFForm, group: string) {
  try {
    return form.getRadioGroup(group);
  } catch (err: unknown) {
    const rg = form.createRadioGroup(group);
    rg.enableOffToggling();
    return rg;
  }
}

function addRadioField(form: PDFForm, page: PDFPage, opts: {
  name: string,
  x: number,
  y: number,
  width: number,
  height: number,
  rotate: Rotation,
  group: string,
}) {
  const rg = ensureRadioGroup(form, opts.group);
  rg.addOptionToPage(opts.name, page, { ...opts });
}

function drawText(page: PDFPage, opts: {
  text: string,
  fontSize: number,
  lineHeight: number,
  font: PDFFont,
  x: number,
  y: number,
  width: number,
  height: number,
  rotate: Rotation,
  color?: Color,
  backgroundColor?: Color
}) {
  const fontOffset = TextDimensionEstimator.heightOfFontAtSize(
    opts.fontSize,
    (opts.font as any).embedder.font as fontkit.Font);
  // if opts.height is positive, then the origin for the box bounding this text is (opts.x, opts.y + opts.height), and the opposite corner is (opts.x + opts.width, opts.y).
  //    since the text is drawn downwards, we need to account for this in the text's origin point.
  // if opts.height is negative, then the origin for the box bounding this text is (opts.x, opts.y), and the opposite corner is (opts.x + opts.width, opts.y + opts.height).
  //    conveniently because text is drawn downwards, we don't need to account for opts.height in this case
  // the bottom-left point of the first character to be drawn, which will be then drawn left to right top to bottom.
  const rot0Offset = opts.height < 0
    ? { x: 0, y: -fontOffset }
    : { x: 0, y: opts.height - fontOffset };
  const offset = CoordinateMath.counterRotate(opts.rotate.angle, rot0Offset);

  if (opts.backgroundColor) {
    page.drawRectangle({
      x: opts.x,
      y: opts.y,
      width: opts.width,
      height: opts.height,
      rotate: opts.rotate,
      color: opts.backgroundColor
    });
  }

  page.drawText(opts.text, {
    x: opts.x + offset.x,
    y: opts.y + offset.y,
    size: opts.fontSize,
    maxWidth: opts.width,
    lineHeight: opts.lineHeight,
    font: opts.font,
    rotate: opts.rotate,
    color: opts.color
  });
}

export function getPageInfo(doc: PDFDocument, index: number | PDFPage): { page: PDFPage, dims: PageDims & { rotatedWidth: number; rotatedHeight: number} } {
  const page = index instanceof PDFPage ? index : doc.getPage(index);
  const rotation = page.getRotation();
  // This didn't work if it was -90 degrees, so we put in abs()
  const swap = Math.abs(Math.round(((rotation.type === RotationTypes.Degrees ? rotation.angle : Math.round(180*rotation.angle/Math.PI)) % 180)/90)) === 1 ;

  return {
    page,
    dims: {
      width: Math.abs(page.getWidth()),
      height: Math.abs(page.getHeight()),
      rotatedWidth: Math.abs(swap ? page.getHeight() : page.getWidth()),
      rotatedHeight: Math.abs(swap ? page.getWidth() : page.getHeight()),

      degrees: CoordinateMath.normaliseDegrees(rotation.angle)
    }
  };
}

function drawPoint(page: PDFPage, { color, x, y, width = 2, height = 2 }: {
  color: Color,
  x: number,
  y: number,
  width?: number,
  height?: number
}) {
  page.drawRectangle({
    color,
    x: x - width / 2,
    y: y - height / 2,
    width,
    height
  });
}

function drawDebugRect(page: PDFPage, { x, y, width, height, color, rotate }: {
  x: number,
  y: number,
  width: number,
  height: number,
  color: Color
  rotate: Rotation
}) {
  page.drawRectangle({
    x,
    y,
    width,
    height,
    rotate,
    borderColor: color,
    borderWidth: 1
  });
  drawPoint(page, { x, y, color });
}

/**
 * draw a bunch of stuff that should appear on the top left of the first page,
 * regardless of the page's pdf rotate property.
 */
export function drawFixedReference(doc: PDFDocument) {
  const page = doc.getPage(0);
  const rotation = page.getRotation();
  const dims: PageDims = { width: Math.abs(page.getWidth()), height: Math.abs(page.getHeight()), degrees: rotation.angle };
  const clr0 = rgb(0, 0, 0);
  const clr90 = rgb(1, 0, 0);
  const clr180 = rgb(0, 1, 0);
  const clr270 = rgb(0, 0, 1);
  const clrRef = rgb(1, 0, 1);

  function drawPointInner(point: Point, color: RGB = clr0) {
    page.drawRectangle({
      ...(CoordinateMath.orientToPage(point, dims, HtmlPdfReflection)),
      width: 1,
      height: 1,
      color
    });
  }

  // only appears when the page is upright
  drawPointInner({ x: 0, y: 0 }, clrRef);
  drawPointInner({ x: 1, y: 1 }, clrRef);
  drawPointInner({ x: 2, y: 2 }, clrRef);
  drawPointInner({ x: 30, y: 30 }, clrRef);
  drawPointInner({ x: 24, y: 24 }, clr0);
  drawPointInner({ x: 24, y: 25 }, clr90);
  drawPointInner({ x: 25, y: 25 }, clr180);
  drawPointInner({ x: 25, y: 24 }, clr270);
  drawPointInner({ x: 20, y: 20 }, clrRef);
}

function drawReferenceText(page: PDFPage, font: PDFFont, text: string, x: number, y: number, width: number, height: number, rotate: Rotation, color: Color) {
  page.drawRectangle({
    width,
    height,
    rotate,
    x,
    y,
    borderWidth: 1,
    borderColor: color
  });
  drawText(page, {
    text,
    x,
    y,
    width,
    height,
    fontSize: defaultFontSize,
    lineHeight: defaultLineHeight,
    rotate,
    font,
    color
  });
}

export async function drawReferenceTexts(doc: PDFDocument) {
  const page = doc.getPage(0);
  const font = await doc.embedFont(StandardFonts.Helvetica);
  drawReferenceText(page, font, 'rot0', 50, 50, 25, 25, degrees(0), rgb(1, 0, 0));
  drawReferenceText(page, font, 'rot0-', 75, 75, 25, -25, degrees(0), rgb(1, 0, 0));
  drawReferenceText(page, font, 'rot90', 100, 100, 25, 25, degrees(90), rgb(0, 1, 0));
  drawReferenceText(page, font, 'rot90-', 125, 125, 25, -25, degrees(90), rgb(0, 1, 0));
  drawReferenceText(page, font, 'rot180', 150, 150, 25, 25, degrees(180), rgb(0, 0, 1));
  drawReferenceText(page, font, 'rot180-', 175, 175, 25, -25, degrees(180), rgb(0, 0, 1));
  drawReferenceText(page, font, 'rot270', 200, 200, 25, 25, degrees(270), rgb(1, 0, 1));
  drawReferenceText(page, font, 'rot270-', 225, 225, 25, -25, degrees(270), rgb(1, 0, 1));

  function drawPoint(x: number, y: number) {
    page.drawRectangle({
      x, y, width: 2, height: 2,
      color: rgb(0,0,0)
    });
  }

  drawPoint(50, 50);
  drawPoint(75, 75);
  drawPoint(100, 100);
  drawPoint(125, 125);
  drawPoint(150, 150);
  drawPoint(175, 175);
  drawPoint(200, 200);
  drawPoint(225, 225);

}

/**
 * Leans heavily on content at
 * https://github.com/Hopding/pdf-lib/blob/b8a44bd24b74f4f32456e9809dc4d2d9dc9bf176/src/core/embedders/CustomFontEmbedder.ts
 */
export class TextDimensionEstimator {
  private cache = new Map<SupportedFontFamily, fontkit.Font>();

  estimateTextDimensions(text: string, fontFamily: SupportedFontFamily | string | undefined, fontSize: number, opts?: { nopad?: boolean, hFudge?: number, forceTransformFn?: (b64: string) => Uint8Array }) {
    const name = mapFontName(fontFamily);
    let font = this.cache.get(name);
    if (!font) {
      const data = getFontData(name, opts?.forceTransformFn);
      font = fontkit.create(data);
      this.cache.set(name, font);
    }

    return {
      width: this.widthOfTextAtSize(
        opts?.nopad
          ? text
          : (text + ' '),
        fontSize,
        font),
      height: TextDimensionEstimator.heightOfFontAtSize(fontSize, font, { descender: true, fudge: opts?.hFudge })
    };
  }

  widthOfTextAtSize(text: string, size: number, font: fontkit.Font): number {
    const { glyphs } = font.layout(text);
    const sum = glyphs.map(glyph => glyph.advanceWidth).reduce((acc, x) => acc + x, 0);
    return (sum / font.unitsPerEm) * size;
  }

  static heightOfFontAtSize(
    size: number,
    font: fontkit.Font,
    options: { descender?: boolean, fudge?: number } = {},
  ): number {
    const fudge = options.fudge ?? 1;
    const { ascent, descent, bbox } = font;

    // prefer to use the ascent/descent values if they're specified - the results typically look better.
    // "units" are a font-specific thing, and then the font says how many "units" fit in 1 em.
    if (ascent && descent) {
      const height = options.descender
        ? ascent - descent
        : ascent;
      return (height/font.unitsPerEm) * size * fudge;
    }

    // bbox dimensions are a fallback option.
    const height = options.descender
      ? bbox.maxY - bbox.minY
      : bbox.maxY;
    return (height / font.unitsPerEm) * size * fudge;
  }
}

import { SigningPlacementStrategy } from '../../yjs-schema/property';
import { PartyCategory } from '../../yjs-schema/property/form';
import { PDFRef } from '@cantoo/pdf-lib';
export type ExtractedFieldAppearance = {
  fontName?: string;
  fontSize?: number;
  colour?: { r: number, g: number, b: number},
  backgroundColour?: { r: number, g: number, b: number},
  borderColour?: { r: number, g: number, b: number },
  borderWidth?: number,
  borderStyle?: 'solid' | 'underlined' | 'bevel' | 'dashed' | 'inset'
};

export type ExtractedField = {
  id: string;
  positions: {
    x: number;
    y: number;
    width: number;
    height: number;
    /**
     * index
     */
    page: number;
  }[];
  /**
   * If the field extractor is asked to include appearance data it will appear (hah!) here.
   */
  appearance?: ExtractedFieldAppearance;
  _debug?: Record<string, any>;
};

export type PageDimension = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export interface ImagePlacement {
  name: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

export enum ImagePartyType {
  Unknown = 0,
  Vendor = 1,
  Purchaser = 2,
  Agent = 3,
  Landlord = 4,
  Tenant = 5,
  Manager = 6,
  Accountant = 7,
  Applicant = 8,
  Assignee = 9,
  Assignor = 10,
  Auctioneer = 11,
  Auditor = 12,
  Auth = 13,
  Bidder = 14,
  Clerk = 15,
  Client = 16,
  CoOwner = 17,
  Consumer = 18,
  Contractor = 19,
  Council = 20,
  Customer = 21,
  Declarant = 22,
  Deligate = 23,
  Guarantor = 24,
  InTenant = 25,
  OutTenent = 26, // typo here - intentional to match the form
  Issuer = 27,
  Lawyer = 28,
  Legal = 29,
  Lessee = 30,
  Lessor = 31,
  Mortgagee = 32,
  Name = 33,
  Offeror = 34,
  Officer = 35,
  Owner = 36,
  Principal = 37,
  Professional = 38,
  Proxy = 39,
  Seller = 40,
  Signature = 41,
  Witness = 42,
  Guest = 43,
  GenericA = 44,
  GenericB = 45,
  GenericC = 46,
  GenericD = 47,
  GenericE = 48,
  GenericF = 49,
  GenericG = 50,
  GenericH = 51,
}

export const ImagePartyTypeStr = {
  [ImagePartyType.Unknown]: 'unknown',
  [ImagePartyType.Vendor]: 'vendor',
  [ImagePartyType.Purchaser]: 'purchaser',
  [ImagePartyType.Agent]: 'agent',
  [ImagePartyType.Landlord]: 'landlord',
  [ImagePartyType.Tenant]: 'tenant',
  [ImagePartyType.Manager]: 'manager',
  [ImagePartyType.Accountant]: 'accountant',
  [ImagePartyType.Applicant]: 'applicant',
  [ImagePartyType.Assignee]: 'assignee',
  [ImagePartyType.Assignor]: 'assignor',
  [ImagePartyType.Auctioneer]: 'auctioneer',
  [ImagePartyType.Auditor]: 'auditor',
  [ImagePartyType.Auth]: 'auth',
  [ImagePartyType.Bidder]: 'bidder',
  [ImagePartyType.Clerk]: 'clerk',
  [ImagePartyType.Client]: 'client',
  [ImagePartyType.CoOwner]: 'coowner',
  [ImagePartyType.Consumer]: 'consumer',
  [ImagePartyType.Contractor]: 'contractor',
  [ImagePartyType.Council]: 'council',
  [ImagePartyType.Customer]: 'customer',
  [ImagePartyType.Declarant]: 'declarant',
  [ImagePartyType.Deligate]: 'deligate',
  [ImagePartyType.Guarantor]: 'guarantor',
  [ImagePartyType.InTenant]: 'intenant',
  [ImagePartyType.OutTenent]: 'outTenent', // typo here - intentional to match the form
  [ImagePartyType.Issuer]: 'issuer',
  [ImagePartyType.Lawyer]: 'lawyer',
  [ImagePartyType.Legal]: 'legal',
  [ImagePartyType.Lessee]: 'lessee',
  [ImagePartyType.Lessor]: 'lessor',
  [ImagePartyType.Mortgagee]: 'mortgagee',
  [ImagePartyType.Name]: 'name',
  [ImagePartyType.Offeror]: 'offeror',
  [ImagePartyType.Officer]: 'officer',
  [ImagePartyType.Owner]: 'owner',
  [ImagePartyType.Principal]: 'principal',
  [ImagePartyType.Professional]: 'Professional',
  [ImagePartyType.Proxy]: 'proxy',
  [ImagePartyType.Seller]: 'seller',
  [ImagePartyType.Signature]: 'signature',
  [ImagePartyType.Witness]: 'witness',
  [ImagePartyType.Guest]: 'guest',
  [ImagePartyType.GenericA]: 'generic_a',
  [ImagePartyType.GenericB]: 'generic_b',
  [ImagePartyType.GenericC]: 'generic_c',
  [ImagePartyType.GenericD]: 'generic_d',
  [ImagePartyType.GenericE]: 'generic_e',
  [ImagePartyType.GenericF]: 'generic_f',
  [ImagePartyType.GenericG]: 'generic_g',
  [ImagePartyType.GenericH]: 'generic_h'
};

export interface ImageEncodedSigningParty {
  partyType: PartyCategory;
  mergeCount?: number;
  number: number;
  multipleOfType?: boolean;
  fieldType: 'initial' | 'signature';
}

export interface ImagePagePlacement extends ImagePlacement {
  pageIndex: number;
  party?: ImageEncodedSigningParty;
  ref?: PDFRef;
  strategy?: SigningPlacementStrategy;
}

export interface StaticTextPlacement {
  pageIndex: number,
  text: string,
  top: number,
  left: number,
  bottom: number,
  right: number,
  size: number
}
